
$(document).ready(function()
{
    /*Cursor*/
    $(document).mousemove(function(event){
        var x = event.pageX;
        var y = event.pageY;
        $(".cursor").css({"left":x, "top":y});
    });

    $("a, .menu-button, #menu-close, #to-top, .button, .swiper-button-prev, .swiper-button-next").hover(function()
    {
        $(".cursor .dot-1, .cursor .dot-2").toggleClass("hovered")
    });

    /*Swiper*/
    var mySwiper = new Swiper('.swiper-container',
        {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            spaceBetween: 70,
            slidesPerView: 8,
            breakpoints: {
                1366: {
                    slidesPerView: 6,
                },
                1024: {
                    slidesPerView: 5,
                },
                900: {
                    slidesPerView: 4,
                },
                720: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
            },
        });

    /*Modal*/
    $(".request-button").on("click", function()
    {
        $("#overlay").fadeIn(500, function()
        {
            $("#modal-request").fadeIn(300);
        });
    });

    $(".question-button").on("click", function()
    {
        $("#overlay").fadeIn(500, function()
        {
            $("#modal-question").fadeIn(300);
        });
    });

    $("span.modal-close, #overlay").on("click", function()
    {
        $("div.modal-window").fadeOut(300);
        $("#overlay").fadeOut(500);
        $("div.form-errors, div.form-success").hide();
    });

    /*Input mask*/
    $("input[name='phone']").mask("+7(999) 999-99-99", {placeholder: "_"});

    /*Mobile menu*/
    $(".menu-button").on("click", function()
    {
        $("#overlay").fadeIn(500, function()
        {
            $("#main-menu").addClass("showed");
        });
    });

    $("#menu-close, #overlay").on("click", function()
    {
        $("#main-menu").removeClass("showed");
        $("#overlay").fadeOut(500);
    });

    $("#main-menu li:has(ul)").children("a").addClass("arrow");

    $("#main-menu a.arrow").on("click", function (event)
    {
        $(this).toggleClass("active");
        $(this).parent().siblings().children("ul").hide(400).prev("a.active").removeClass("active");
        $(this).next("ul").slideToggle();
        return false;
    });

    /*Плавный переход к якорям*/
    $(".anchor").on("click", function (event)
    {
        event.preventDefault();
        var id  = $(this).attr("href"),
            top = $(id).offset().top;
        $("body,html").animate({scrollTop: top}, 1000);
    });

    /*Fixed header, toTop*/
    $(window).scroll(function ()
    {
        if($(this).scrollTop() >= 220)
        {
            $("#fixed-header").slideDown();
            $("#to-top").show();
        }
        else
        {
            $("#fixed-header").slideUp();
            $("#to-top").hide();
        }
    });

    $("#to-top").on("click", function()
    {
        var scroll = $(document).scrollTop();
        $("body, html").animate({scrollTop: 0}, scroll / 2);

        return false;
    });

    /*
    $("#tags a").on("click", function(event)
    {
        event.preventDefault();
    });
    */

    function windowSize(){
        if($(window).width() <= 480)
            $(".page-top .right-side").has("p").css("padding-top", "30px");
    };

    $(window).on('load resize', windowSize);

    /* Show more projects */
    $("div.projects-list div.button.green").on("click", function()
    {
        var ids = [];
        var _this = $(this);
        var service = $("select[name='projects']").val();

        $("div.projects-list a.project-item").each(function() {ids.push($(this).data("id"));});

        $.ajax({
            type: "POST",
            dataType: "html",
            url: rootPath + "views/ajax/common.php",
            data: "ids=" + JSON.stringify(ids) + "&service=" + service + "&action=add-projects",
            success: function(data)
            {
                if(data)
                {
                    data = JSON.parse(data);
                    $(data['html']).insertAfter($("div.projects-items a.project-item").last());

                    if(!data['has-more-records'])
                        _this.remove();
                }
            }
        })
    });

    /* Show more reviews */
    $("div.reviews-list div.button.green").on("click", function()
    {
        var ids = [];
        var _this = $(this);

        $("div.reviews-list div.review").each(function() {ids.push($(this).data("id"));});

        $.ajax({
            type: "POST",
            dataType: "html",
            url: rootPath + "views/ajax/common.php",
            data: "ids=" + JSON.stringify(ids) + "&action=add-reviews",
            success: function(data)
            {
                if(data)
                {
                    data = JSON.parse(data);
                    $(data['html']).insertAfter($("div.reviews-list div.review").last());

                    if(!data['has-more-records'])
                        _this.remove();
                }

            }
        })
    });

    /* Show projects by service */
    $("select[name='projects']").on("change", function()
    {
        var service = $(this).val();

        if(service == "0")
            window.location.href = rootPath + "projects/";
        else if(service)
            window.location.href = "?service=" + service;
    });
});